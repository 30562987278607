import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import React, { Component } from 'react';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown,faChevronLeft,faChevronRight,faDownload} from "@fortawesome/free-solid-svg-icons"


const cites = [
  {
    text: "Du bietest ein sehr gutes Preis-Leistungs-Verhältnis in einer sehr angenehmen Arbeitsatmosphäre. Zuverlässig, kompetent und freundlich. Ich empfehle dich gerne weiter!",
    subtext: "flow-institut-freiburg.de",
    author: "Cornelia Grix",
  },
  {
    text: "Kreativ - kompetent - konstruktiv. Und geduldig! Das ist das erste, das mir zur Zusammenarbeit mit Dominik einfällt!",
    subtext: "praesenz.net",
    author: "Dr. Sandra Michels",
  },
  {
    text: "Dominik hat immer ein offenes Ohr! Ausserdem hat er immer neue tollen Ideen und arbeitet schneller als der Wind!",
    subtext: "freie-heilpraktikerschule.de",
    author: "Celia Schmierer",
  },
  {
    text: "Herr Ziegenhagel hat uns sehr kompetent, ausführlich und in angenehmer Atmosphäre zum Webauftritt unseres Fotolabors beraten. [...] Obwohl wir uns selbst bereits seit vielen Jahren mit dem Thema befassen, konnten wir viel Neues und Originelles lernen. Vielen Dank!",
    subtext: "tkexe.eu",
    author: "Torsten Krieg",
  }
]

export default class Page extends Component {
    constructor(props) {
        super(props)
        this.state={
            h1:"",p:"",id:"",overlayVisible:false,
            currentCite: 1
        }

      
    } 

   
    render() {
        return (
            <Layout container={false}>

            <link rel="prefetch" href={require("../images/landing/spiral_staircase_1700.jpg")}  />

            <SEO title="Webseite & Imagefilm | Kreativ Webagentur aus Dresden" />

            <div className={(this.state.overlayVisible ? "blured" : "" )+ " box-overlay"} id={this.state.id}>
                <div>
                        <div className="img"></div>
                        <div className="textbox">
                            <h1>{this.state.h1}</h1>
                            <p className="text-gray">{this.state.p}</p>
                            <a onClick={()=>{
                                this.setState({overlayVisible:false})
                            }}>Schliessen</a>
                        </div>
                </div>
            </div>

            <div style={{background:"skyblue",width:"100%",overflow:"hidden"}}>
            <div className="container-fluid hero" id="spiral_staircase">
                <div className="container">
                    <div className="textbox text-center">
                        <h1 className="text-white mt-2">Look. Think. Deliver.</h1>
                        <p className="hidden-sm fade-in-late text-white mt-2"> Bitte nach unten scrollen.</p>
            {/*
            <FontAwesomeIcon className="scroll-trigger" icon={faChevronDown}/>

                        <picture>
                            <source srcSet={require("../images/landing/mouse_down.png.webp")} type="image/webp"/>
                            <source srcSet={require("../images/landing/mouse_down.png")} type="image/png"/>
                            <img style={{marginTop: "40px", marginBottom: "10px"}} src={require("../images/landing/mouse_down.png")}/>
                        </picture>
                        */}

                    </div>
                </div>
            </div>
            </div>



        <div className="fountain-image" id="clients-feedback">

            <div className="featurebox" style={{ margin: "auto", display: "block", marginTop: -10, padding: "30px", maxWidth: "700px" }}>
              <h1>Feedback</h1>
            </div>

            <div className="container" style={{ margin: "30px auto", marginBottom: 120 }}>
              <div className="featurebox white" style={{ margin: "auto", display: "block", padding: "50px 00px", paddingBottom: "30px", maxWidth: "calc( 100% - 100px )" }}>
                <div className="comment-chevron-left" onClick={() => {
                  if (this.state.currentCite === 0)
                    this.setState({ currentCite: cites.length - 1 });
                  else this.setState({ currentCite: this.state.currentCite - 1 })
                }}><FontAwesomeIcon icon={faChevronLeft} /></div>
                <div className="comment-chevron-right" onClick={() => {
                  if (this.state.currentCite === cites.length - 1)
                    this.setState({ currentCite: 0 });
                  else this.setState({ currentCite: this.state.currentCite + 1 })
                }}><FontAwesomeIcon icon={faChevronRight} /></div>
            <div className="padding-fix">
                <h1>"{cites[this.state.currentCite].text}"</h1>
                <h2 className="text-center mt-3">{cites[this.state.currentCite].author}</h2>
                <p className="text-center primary mt-1">{cites[this.state.currentCite].subtext}</p>
</div>
              </div>
            </div>

            <div className="splash-down">
              <FontAwesomeIcon className="animate-down" icon={faChevronDown} style={{}} />
            </div>
            <br />

        </div>




            <div className="container-fluid hero" id="eggs">
                <img loading="lazy" src={require("../images/landing/eggs_left_1500.jpg")} />
                <div className="container">
                    <div className="textbox">
                        <h1>Komplette Projekt<wbr/>begleitung</h1>
                        <p className="text-gray">Wir gestalten gerne umfassend Ihren Online Auftritt.
            Egal ob Kampagne, Webseite, App, Design oder Werbefilm.</p>
                        <Link to="/services" className="text-lime" style={{marginRight:20,display:"inline-block"}}>Alle Services ›</Link>
            <div className="hidden-xs" style={{display:"none"}}>
<a href={require("../downloads/Ziegenhagel Media - Info.pdf")} className="text-lime" target="_blank"> Alle Infos als PDF ›</a> 
</div>
                    </div>
                </div>
                <img src={require("../images/landing/eggs_right_1500.jpg")} />
            </div>


            <div className="container-fluid hero" id="outside_box">


                <img loading="lazy" src={require("../images/landing/box_left.svg")} />
                <img loading="lazy" className="box-top" src={require("../images/landing/box_top.svg")} />
                <div className="container">
                    <div className="textbox">
                        <h1>Outside <div class="breaker"></div><div style={{
                                display:"inline-block",transform:"rotate(-7deg)",position:"absolute",background:"#333",
                                zIndex:"-7", color: "#333", padding: "7px", marginTop: "-6px", marginLeft: "-7px"
                            }}>the box</div><span style={{color:"white"}}>the box</span></h1>
                        <p className="text-gray">Kreative Überraschungen sind unser Ding, geben Sie uns ein weißes Blatt!  </p>
                        <Link to="/stories" className="text-lime">Zum Portfolio ›</Link>
                    </div>
                </div>
                    <img loading="lazy" src={require("../images/landing/box_right.svg")} />
            </div>





            <div className="container-fluid hero" id="simple" style={{display:"none"}}>
                <img loading="lazy" src={require("../images/landing/green_leaf_1500.jpg")} />
                <div className="container">
                    <div className="textbox">
                        <h1>Einfach.</h1>
                        <br/>
                        <p className="text-gray">Wir halten es für Sie simpel!  <br/>
                        Die Inhalte der Webseite zu bearbeiten ist kinderleicht.  <br/> Alles weitere bleibt unsere Aufgabe.  </p>
                        <p className="text-gray text-small">* Details unter wordpress.org</p>
                        <Link to="/services/webseite" className="text-lime">Einfache Webseite ›</Link>
                    </div>
                </div>
            </div>


            <div className="container-fluid hero" id="design_moves">
                <div className="container-fluid">
                    <video poster={require("../images/landing/design_moves.jpg")} loading="lazy" autoPlay
                           playsInline={"playsInline"}
                           loop={"loop"} muted={"muted"} src={require("../images/landing/design_moves.mp4")}  />
                    <div className="textbox">
                        <h1 className="text-white">Design steht niemals still</h1>
                        <p className="text-gray">Wir auch nicht, dazu sind wir viel zu neugierig … </p>
                        <Link to="/services/apps" className="text-lime">App Entwicklung ›</Link>
                    </div>
                </div>
            </div>


            <div className="container-fluid hero" id="rocket_launching">
                <div className="container">
                    <div className="textbox">
                        <h1 className="text-white">High Performance</h1>
                        <p className="text-gray">Bis zu 6x schnellere Bereitstellung der Inhalte durch Nutzung von Cloudflares Cookie-basiertem Schlüssel-Caching, 10x schnellere globale Reaktionszeit.</p>
                        <p className="text-gray text-small">* Details unter cloudflare.com, webgo.de</p>

                        <a onClick={()=>{
                            this.setState({id:"performance",p:`
                            Ihre WebGo Webseite wird nur wenige 100 Meter vom weltgrößten Internetknoptenpunkt DE-CIX gehostet.
                            Statische Webseiten Inhalte werden sogar durch Nutzung von Cloudflares Content Delivery Networks 
                            auf über 200 geographisch verteilten Rechenzentren direkt 
                            vorgehalten und ermöglichen schnellere Inhaltsbereitstellung.
                            `, h1:"Bereitstellungs Netzwerk",overlayVisible:true})
                        }} className="text-lime">Mehr erfahren ›</a>


                    </div>
                    <img loading="lazy" src={require("../images/landing/rocket_launching_1500.jpg")} />
                </div>
            </div>



            <div className="container-fluid hero" id="eco_power">
                <div className="container">
                    <img src={require("../images/landing/hand_with_plant_1500.jpg")} />
                    <div className="textbox">
                        <h1 className="mt-4">Media for Future</h1>
                        <p className="text-gray">Nicht nur unsere Lieblingsfarbe ist Grün, auch die Server unserer WebGo Webseiten, denn sie werden mit 100% Ökostrom betrieben.</p>
                        <p className="text-gray text-small">* Details unter webgo.de/technik/oekostrom</p>
                        <Link to="/gogreen" className="text-lime">Go green ›</Link>

                        <div className="partners">

                            <picture>
                                <source srcSet={require("../images/landing/partner_webgo.jpg.webp")} type="image/webp"/>
                                <source srcSet={require("../images/landing/partner_webgo.jpg")} type="image/jpg"/>
                                <img style={{height:"60px"}} className="border" src={require("../images/landing/partner_webgo.jpg")}/>
                            </picture>


                            <picture>
                                <source srcSet={require("../images/landing/partner_google.jpg.webp")} type="image/webp"/>
                                <source srcSet={require("../images/landing/partner_google.jpg")} type="image/jpg"/>
                                <img style={{height:"60px",marginLeft:"20px"}} className="border" src={require("../images/landing/partner_google.jpg")}/>
                            </picture>
                        </div>


                    </div>
                </div>
            </div>



            <div className="container-fluid hero" id="privacy">
                <div id="gray"></div>
                <div className="container">
                    <div className="textbox">
                        <h1 className="text-white">D•••nschutz </h1>
                        <p className="text-gray">Ihre sensiblen Daten bleiben innerhalb von Deutschland und werden mit einem der sichersten RSA Verfahren verschlüsselt.  </p>


                        <a onClick={()=>{
                            this.setState({id:"encryption",p:`
                            Deutschlands Datenschutzgesetze gehören zu den härtesten der Welt. Mit Serverstandort in Deutschland sind sie nicht nur auf der sicheren Seite,
                            sondern bieten auch durch die zentrale Lage eine optimale Erreichbarkeit für Alle.
`, h1:"Datenschutz",overlayVisible:true})
                        }} className="text-lime">Mehr erfahren ›</a>


                    </div>
                    <img src={require("../images/landing/door_locked_1500.jpg")} />
                </div>
            </div>





            <div className="container-fluid hero" id="reliability">
                <div className="container">
                    <div className="textbox">
                        <h1>Immer Online</h1>
                        <p className="text-gray">Wir setzen auf hoch redundante Content Delivery Netzwerke und können dank AlwaysOnline™ auch bei Serverausfällen weiterhin online bleiben.</p>
                        <p className="text-gray text-small">* Details unter cloudflare.com/always-online</p>

                        <a onClick={()=>{
                            this.setState({id:"always",p:`Always Online™ ist eine Funktion, die eine statische Version Ihrer Seiten zwischenspeichert, falls Ihr Server offline geht.
Sollte Ihr ursprünglicher Server einmal nicht verfügbar sein, stellt Cloudflare eine begrenzte Kopie Ihrer im Cache gespeicherten Website zur Verfügung,
um diese für Ihre Besucher online zu halten.`, h1:"Always Online™",overlayVisible:true})
                        }} className="text-lime">Mehr erfahren ›</a>

                    </div>
                </div>

                <picture>
                  <source srcSet={require("../images/landing/bamboo_scaffold_1700.jpg.webp")} type="image/webp"/>
                  <source srcSet={require("../images/landing/bamboo_scaffold_1700.jpg")} type="image/jpeg"/> 
                  <img src={require("../images/landing/bamboo_scaffold_1700.jpg")} width="100%"/>
                </picture>

            </div>


            <div className="container-fluid hero" id="coffee">
                <div className="container">
                        <picture>
                            <source srcSet={require("../images/landing/coffee_1500.jpg.webp")} type="image/webp"/>
                            <source srcSet={require("../images/landing/coffee_1500.jpg")} type="image/jpeg"/>
                            <img src={require("../images/landing/coffee_1500.jpg")}/>
                        </picture>


                    <div className="textbox">
                        <h1 className="text-white">Auf n Kaffee?  </h1>
                        <p className="text-white">Erzählen Sie uns von Ihrer Story, wir sind gespannt!  </p>
                        <Link to="/mehr-erfahren" className="text-white btn btn-lime-outline">Jetzt anfragen ›</Link>
                    </div>
                </div>
            </div>

            </Layout >
        )
    }
}
